// -----------------------------
// swiper系
// -----------------------------

(function () {
  //swiper
  let kvSlide = new Swiper(".js-kv-slide", {
    pagination: {
      el: ".swiper-custom-pagination",
      clickable: true,
    },
    autoplay: false,
    loop: false,
    speed: 1000,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 0,
    breakpoints: {
      769: {
        slidesPerView: 1.5,
      },
    },
  });
  //商品詳細サムネイル
  const sliderThumbnail = new Swiper(".js-pd-thumb-slide", {
    slidesPerView: 5,
    // freeMode: true,
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-btn-next",
      prevEl: ".swiper-btn-prev",
    },
  });

  //商品詳細スライダー
  const slider = new Swiper(".js-pd-slide", {
    thumbs: {
      swiper: sliderThumbnail[0],
    },
  });
})();
